import stepMixin from '../../../mixins/stepMixin'
import customerMixin from '../../../mixins/customerMixin'
import checkTaskMixin from '../../../mixins/checkTaskMixin'
import TextList from '../../textList/TextList.vue'
import FormCheck from '../../form/formCheck/FormCheck.vue'
import { mapActions } from 'vuex'

export default {
    name: 'IdentityCard',

    props: {
        stepIsValid: {
            type: Boolean,
            required: true
        }
    },

    components: {
        TextList,
        FormCheck
    },

    mixins: [stepMixin, checkTaskMixin, customerMixin],

    data() {
        return {
            dataMatch: null
        }
    },

    computed: {
        dataMatchLabel() {
            return this.isDrivingServiceTask
                ? 'Daten von Personalausweis und Fahrer/in stimmen überein'
                : this.hasAuthorizedRepresentative &&
                  !this.contractPartnerOnSite
                ? 'Daten von Personalausweis und Bevollmächtigte/r stimmen überein'
                : 'Daten von Personalausweis und Primäre/r Fahrer/in stimmen überein'
        },
    },

    methods: {
        ...mapActions({
            setForm: 'form/setForm'
        }),

        validate() {
            let isValid = true

            if (this.isEmpty(this.field.dataMatch) || !this.field.dataMatch) {
                isValid = false
            }

            this.$emit('update:stepIsValid', isValid)
        },
    }
}
